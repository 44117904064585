<template>
  <div class="container">
    <!-- 查看课程详情 -->
    <el-dialog
      :title="$t('courses.view')"
      width="95%"
      top="2.5%"
      :visible.sync="isView"
      center
    >
      <view-course :courseId="courseId"></view-course>
    </el-dialog>
    <!-- 设置日期 -->
    <el-dialog title="Set Date" :visible.sync="showDate" center>
      <div class="block">
        <el-date-picker
          style="width:100%"
          v-model="dateSetter"
          type="daterange"
          range-separator=" ~ "
          start-placeholder="Start Date"
          end-placeholder="End Date"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDate = false">
          {{ $t("alert.cancel") }}
        </el-button>
        <el-button type="primary" @click="setDate">
          {{ $t("alert.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <div class="ivy-courses-title">
      <el-page-header
        :title="$t('courses.back')"
        @back="historyBack"
        :content="$t('courses.course_data')"
      >
      </el-page-header>
    </div>
    <div class="ivy-courses-list">
      <el-row class="ivy-row">
        <el-col :span="6">
          <h3>{{ $t("courses.courses") }}</h3>
        </el-col>
        <el-col :span="12">
          <el-button
            type="success"
            icon="el-icon-view"
            @click="isView = true"
            >{{ $t("courses.view") }}</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row class="ivy-row">
        <el-col :span="6">
          <h3>{{ $t("courses.add_student") }}</h3>
        </el-col>
        <el-col :span="12">
          <el-select
            @change="addstudent"
            filterable
            placeholder="Students"
            style="width: 100%"
            v-model="selectValue"
          >
            <el-option
              v-for="item in students"
              :key="item.id"
              :label="item.name + '(' + item.email + ')'"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="ivy-row">
        <el-col :span="6">
          <h3>{{ $t("courses.students_list") }}</h3>
        </el-col>
      </el-row>

      <el-table
        ref="multipleTable"
        :data="selectedStudents"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="editStudents"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="Students" width="300">
          <template slot-scope="scope">
            {{ scope.row.name }}({{ scope.row.email }})
          </template>
        </el-table-column>
        <el-table-column label="Start Date" width="300">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.start_time"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
            ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="End Date" width="300">
          <template slot-scope="scope">
            <el-date-picker
              v-model="scope.row.end_time"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
            ></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Actions" width="200">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="showProgress(scope.row.user_id)"
              type="text"
              size="small"
            >
              Progress
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:20px" v-show="checkStudents.length > 0">
        <el-button
          @click="removeStudents"
          size="mini"
          type="danger"
          icon="el-icon-delete"
        ></el-button>
        <el-button
          @click="showDate = true"
          size="mini"
          icon="el-icon-date"
        ></el-button>
      </div>
      <el-divider></el-divider>
      <el-row class="ivy-row">
        <el-col :span="6">
          <el-button type="primary" @click="saveStudents">{{
            $t("courses.save")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import onlineCourseApi from "@/apis/onlineCourse";
import ViewCourse from "@/views/courses/components/ViewCourse";

export default {
  components: {
    ViewCourse
  },

  mixins: [],

  props: [],
  data() {
    return {
      students: [],
      selectedStudents: [],
      checkStudents: [],
      selectValue: "",
      showDate: false,
      isView: false,
      dateSetter: []
    };
  },
  computed: {
    ...mapGetters("user", ["langValue"]),
    courseId() {
      return this.$route.query.id;
    }
  },
  watch: {},
  mounted() {
    this.getStudents();
  },
  methods: {
    async getStudents() {
      const data = await onlineCourseApi.studentsList({
        course_id: this.courseId
      });
      this.students = data.all_students_data;
      this.selectedStudents = data.active_students;
    },
    addstudent(student) {
      let canPush = true;
      for (let i in this.selectedStudents) {
        if (this.selectedStudents[i].email == student.email) {
          canPush = false;
        }
      }
      if (canPush) {
        this.selectedStudents.push(student);
      } else {
        this.$message({
          message: "已经存在！",
          type: "warning"
        });
      }
      this.selectValue = "";
    },
    removeStudent(index) {
      this.selectedStudents.splice(index, 1);
    },
    async saveStudents() {
      await onlineCourseApi.assignOnlineCourse({
        course_id: this.courseId,
        students_list: this.selectedStudents
      });
      this.$message({
        message: "success!",
        type: "success"
      });
      this.getStudents();
    },
    async removeStudents() {
      await onlineCourseApi.removeStudent({
        course_id: this.courseId,
        students_list: this.checkStudents
      });
      this.$message({
        message: "success!",
        type: "success"
      });
      this.getStudents();
    },
    setDate() {
      this.checkStudents.map(student => {
        student.start_time = this.dateSetter[0];
        student.end_time = this.dateSetter[1];
      });
      this.showDate = false;
    },
    editStudents(val) {
      this.checkStudents = val;
    },
    showProgress(student_id) {
      this.routerPush({
        name: "studentProgress",
        query: {
          course_id: this.courseId,
          student_id: student_id
        }
      });
    }
  }
};
</script>

<style scoped>
.ivy-courses-title {
  margin-top: 20px;
}
.ivy-row {
  margin-bottom: 20px;
}
.ivy-tag {
  margin: 0 10px 10px 0;
}
.ivy-courses-list {
  background: #fff;
  margin: 20px 0;
  padding: 30px;
  border: 1px solid #dcdfe6;
}
</style>
